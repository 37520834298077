import { isRouteErrorResponse, useRouteError } from '@remix-run/react'
import { AppwriteException } from 'appwrite'
import * as Sentry from '@sentry/react'

const useErrorBoundary = () => {
  const error = useRouteError()
  console.error(error)
  let status = 'An Unknown Error has occurred',
    description = '',
    message = ''
  if (error instanceof AppwriteException) {
    status = error.code.toString()
    description = error.name
    message = error.message
  }

  if (isRouteErrorResponse(error)) {
    status = error.status.toString()
    description = error.statusText
    message = error.data
  }
  if (import.meta.env.PROD) {
    Sentry.captureException(error)
  }
  return {
    status,
    description,
    message,
  }
}

export default useErrorBoundary
